
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import { Header, Textarea, QuestionWrapper } from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    Textarea,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 6;
    const router = useRouter();
    const title =
      "Do you have any drug allergies? Please list the drug name(s) and what happens when you use it.";
    const subtitle = "Submit N/A if not applicable.";

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      onAnswered,
      title,
      subtitle,
      questionNumber,
    };
  },
});
